.dnd-column.hidden {
  display: none;
}

.dnd-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  font-weight: 500;
  font-size: 18px;
  min-height: 53px;
}

.dnd-header svg {
  cursor: pointer;
  font-size: 24px;
  margin: -4px;
}

.dnd-header svg:hover {
  color: #4f49b1;
}

.dnd-body {
  flex-grow: 1;
  overflow: auto;
}

.dnd-absolute {
  position: absolute;
  top: 0;
  background-color: #f3f3f9;
}

.ant-popover .ant-popover-arrow-content {
  background-color: #1e1e1e !important;
}

.ant-popover .ant-popover-inner {
  width: 320px;
  background-color: #1e1e1e;
  border-radius: 4px;
}

.ant-popover .ant-popover-inner-content {
  padding: 21px;
  color: #ffffff;
}

.ant-popover label {
  color: #ffffff;
}

.ant-popover .ant-input-number {
  color: #ffffff;
  background-color: #2b2b2b;
  border: none;
  border-radius: 5px;
  width: 100%;
  box-shadow: none;
}

.ant-popover .ant-input-number:hover,
.ant-popover .ant-input-number:focus {
  border: none;
}

.ant-popover .ant-input-number-input {
  color: #ffffff;
  background-color: #2b2b2b;
  border: 1px solid #363636;
}
.ant-popover .ant-input-number-input:disabled {
  color: #6a6a6a;
  background-color: #2b2b2b;
  border: 1px solid #363636;
}
.ant-popover .ant-row.ant-form-item {
  margin-bottom: 12px;
}

.ant-popover .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-popover .ant-col.ant-form-item-label {
  text-transform: capitalize;
}

.dnd-animation-remove ~ div {
  transform: none !important;
}

.clear-search-button {
  box-sizing: border-box;
  border-radius: 6px;
  width: 144px;
  height: 39px;
}

.margin-transition {
  overflow: hidden;
  -webkit-transition: margin 300ms ease-in-out;
  -moz-transition: margin 300ms ease-in-out;
  -o-transition: margin 300ms ease-in-out;
  transition: margin 300ms ease-in-out;
  transition: margin 300ms ease-in-out, box-shadow 300ms ease-in-out;
  margin: 14px;
}

.column {
  scrollbar-gutter: stable;
}
.library-column {
  overflow: hidden;
  transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  -webkit-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  width: 240px;
}

.library-column-closed {
  overflow: hidden;
  -webkit-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  transition: width 300ms ease-in-out, margin 300ms ease-in-out;
  width: 0px;
}
