.ReactModal__Overlay {
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  opacity: 0;
  transform: translateY(100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(100px);
}

.google-logo {
  width: 24px;
  height: 24px;
}
