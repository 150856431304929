.output-wrapper {
  display: flex;
  flex-direction: column;
}

.output-wrapper > * {
  overflow-y: auto;
}
.output-copy-border {
  border-top: 1px solid #d8dee7;
}
.highcharts-container {
  overflow: auto !important;
}
.output-content {
  white-space: pre-wrap;
}

.text-highlight {
  white-space: pre-wrap; /* important for proper line breaks */
}

/* .no-blur {
  position: absolute;
  z-index: 10;
  display: "none";
} 

/*.text-highlight__group {*/
/*  display: inline-block;*/
/*  white-space: normal;*/
/*  position: relative;*/
/*}*/

/*.text-highlight__match {*/
/*  display: inline-block;*/
/*  position: relative;*/
/*  font-size: 16px;*/
/*  border-radius: 2px;*/
/*  padding: 4px 0;*/
/*  white-space: nowrap;*/
/*}*/

/*.text-highlight__label-list {*/
/*  position: absolute;*/
/*  list-style: none;*/
/*  padding: 0;*/
/*  left: 0;*/
/*  margin: 0;*/
/*}*/

.gutter {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: 0.5s;
  z-index: 2;
  flex-basis: 8px !important;
}

.gutter.gutter-vertical {
  cursor: row-resize;
}
.gutter:hover {
  background-color: #f13be9;
}
.text-highlight__text {
  /* should be delete 1 lines when we will add before text */
  line-height: 40px;
  position: relative;
}

.text-highlight__text .label {
  /* should be delete 4 lines when we will add before text */
  position: absolute;
  top: 20px;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  padding: 1px 10px;
  font-size: 10px;
  text-align: center;
  border-radius: 0 10px 10px 10px;
  color: #ffffff;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  overflow: hidden;
  width: max-content;
  white-space: nowrap;
  font-weight: bold;
}

.highcharts-drillup-button {
  display: none;
}
.text-highlight__text .label:hover {
  z-index: 10;
}
.highcharts-button-box {
  height: 35px;
  width: 35px;
}
.copy-output {
  height: 30px;
}

.highcharts-credits {
  display: none;
}

.highcharts-background {
  display: none;
}

.chart-values {
  padding: 0 10px;
}

.chart-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
.chart-tooltip {
  padding: 8px 20px;
  width: 100%;
  background: #1e1e1e;
  border-radius: 4px;
}

/* .chart-tooltip::after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  bottom: -7px;
  border: 10px solid transparent;
  border-top: 6px solid #1e1e1e;
} */

.chart-tooltip .chart-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.chart-tooltip .chart-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #a2a2a2;
}

.highcharts-tooltip > span {
  display: block;
  z-index: 2;
  padding: 9px;
  top: 0;
  left: 0;
}

g.highcharts-tooltip {
  display: none;
}
