.MuiAccordion-root::before {
  display: none;
}

.MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}

.MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin-left: 15px;
  justify-content: space-between;
}

.MuiAccordionSummary-root .MuiAccordionSummary-content .MuiSvgIcon-root {
  font-size: 1.2rem;
  color: lightblue;
  align-self: center;
  transition: 0.3s;
}

.MuiCollapse-root .MuiAccordionDetails-root {
  padding: 0;
  color: white;
}

.gutter {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: 0.5s;
  z-index: 2;
  background-clip: content-box;
}

.gutter.gutter-horizontal {
  padding-inline: 3px;
  cursor: auto;
  cursor: url("../../assets/pages/split-cursor.png") 24 12, auto;
}
.gutter.gutter-vertical {
  padding-block: 3px;

  cursor: auto;
  cursor: url("../../assets/pages/split-cursor-vertical.png") 12 24, auto;
}
.gutter:hover {
  background-clip: content-box;
  background-color: #f13be9;
}

.MuiInputBase-root {
  justify-items: center;
  display: grid;
  align-items: center;
}
