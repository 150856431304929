.checkbox {
    display: inline-block;
    height: 20px;
    border-radius: 3px;
    width: 20px;
    margin: 0 8px 0 0;
    appearance: none;
    border: .5px solid #ccc;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: .2s ease-in-out;
    transition-property: background-color, border;
  }
  
  .checkbox:checked {
    background-color: #8FCD41 !important;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L3.4 6L7 1' stroke='%23E5E5E5' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
    border: 0;
  }
  
  .checkbox:focus {
    outline: none !important;
    border: .5px solid #ccc;
  
  }
  
  