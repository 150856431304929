.columns {
  min-height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 2fr;
}

.columns > .column {
  display: flex;
  flex-direction: column;
}

.column-header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.columns .column-body {
  flex-grow: 1;
}

.buttons-wrapper {
  box-shadow: inset 0px 14px 11px -12px lightgray;
}
.button-pipeline {
  cursor: pointer;
  border-width: 1px;
}
.button-pipeline.disabled {
  cursor: not-allowed;
}

.share-button {
  color: #435bff;
  border-width: 1px;
}

.popover-body {
  font-size: 14px !important;
}

.MuiPaper-root {
  color: white !important;
}
