@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .input-container {
    @apply p-5 px-6 bg-white dark:bg-inputDarkGray border border-borderLight dark:border-inputBorderDarkGray rounded-[5px] text-black dark:text-white focus:outline-none w-full placeholder-formsgray;
  }
}


@layer base {
  html, body {
    font-weight: @apply font-normal;
  }
  b {
    @apply font-bold;
  }
  span,p,div {
    @apply font-normal;
  }

}

* {
  box-sizing: border-box;
}

*:last-child {
  margin-bottom: 0;
}

html {
  font-size: 16px;
  line-height: 18px;
  height: 100%;
  overflow-y: hidden;
}

body {
  font-size: 16px;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

img {
  width: 100%;
  height: auto;
  display: block;
}


figure {
  margin: 0;
}

p,
ul,
ol {
  margin: 0 0 16px 0;
}

* + p,
* + ul,
* + ol {
  margin-top: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.67em 0;
  line-height: 1.25;
}

* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 0.67em;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.preserve-scrollbar {
  scrollbar-gutter: stable;
}