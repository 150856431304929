.type:first-letter {
  text-transform: uppercase;
}
.wrapper {
  display: flex;
  column-gap: 16px;
  overflow-x: auto;
  width: 100%;
}

.checkbox {
  display: inline-block;
  height: 13px;
  border-radius: 3px;
  width: 13px;
  margin: 0;
  margin-right: 8px;
  appearance: none;
  border: 2px solid #fff;
  background-repeat: no-repeat;
  background: transparent;
  background-position: 50% 50%;
  background-color: transparent;
  cursor: pointer;
}

.checkbox:checked {
  border: 0px solid #fff;
  display: inline-block;
  height: 13px;
  border-radius: 3px;
  width: 13px;
  margin: 0;
  margin-right: 8px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background: transparent;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' style='enable-background:new 0 0 100 100;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-1.26-2.62v105.87h105.7V-2.62H-1.26z M69.26,39.96l-16.13,25.2c-1.19,1.87-3.48,2.15-5.1,0.64l-11.3-10.53 c-1.62-1.51-1.97-4.25-0.77-6.12c1.2-1.87,3.48-2.15,5.1-0.64l8.36,7.8l13.96-21.82c1.2-1.87,3.48-2.15,5.1-0.64 C70.11,35.36,70.45,38.1,69.26,39.96z'/%3E%3C/svg%3E%0A");
}
