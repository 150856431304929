.slider-wrapper {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.slider-wrapper span:first-child {
  margin-right: 5px;
}

.slider-wrapper span:last-child {
  margin-left: 5px;
}

.slider-wrapper .ant-slider {
  flex-grow: 1;
}

.settings-icon {
  display: flex;
  font-size: 18px;
  cursor: pointer;
}

input .disabled {
  color: black;
}
.grid-dropdown {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 70px 1fr;
  color: white;
}

.ant-dropdown-menu-item {
  user-select: none !important;
}

.grid-params {
  display: grid;
  grid-template-columns: 1fr 20px 1fr 20px;
  align-items: baseline;
  color: white;
  text-align: center;
}

.ant-input-number-input.error {
  border: 1px solid #723333 !important;
  color: #9e3e1a !important;
}
